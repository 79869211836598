export default [
    {
        name: '【抱き枕カバー】抱き枕カバー♥ご主人様と一緒❤',
        cover: require('@/assets/images/goods/SAMPLE両面2.png'),
        url: 'https://hanazonoserena.booth.pm/items/2082176'
    },
    {
        name: '【ホワイトデー】チュッ💓お返しが嬉しくて堪らないセレナちゃん【短編ボイス】',
        cover: require('@/assets/images/goods/004ccfd5-7611-48be-bae1-8b06ca9714a6_base_resized.jpg'),
        url: 'https://hanazonoserena.booth.pm/items/1272923'
    },
    {
        name: '【コミケ販売】花園セレナお注射しちゃうよ❤アクリルキーホルダー',
        cover: require('@/assets/images/goods/0c02bae673ae5ebde7710f13e2f76269.png'),
        url: 'https://hanazonoserena.booth.pm/items/1758027'
    },
    {
        name: '【バレンタインに💕】ちゅっちゅしちゃう短編ボイスドラマ＋リクエストボイス',
        cover: require('@/assets/images/goods/028adcb0-46ea-4aa3-9eb3-820993ebb278_base_resized.jpg'),
        url: 'https://hanazonoserena.booth.pm/items/1228720'
    },
    {
        name: '【コミケ販売】花園セレナアクリルスタンド',
        cover: require('@/assets/images/goods/3289f4cc56559c66ec249393cfcb5835.png'),
        url: 'https://hanazonoserena.booth.pm/items/1758014'
    },
    {
        name: '【第一弾】花園セレナリクエストボイス',
        cover: require('@/assets/images/goods/0960036b-f6e0-4209-a78d-e9d6e2708712_base_resized.jpg'),
        url: 'https://hanazonoserena.booth.pm/items/1217361'
    },
    {
        name: '【初挑戦！バイノーラル録音】エッ！？シチュエーションボイス【花園セレナ】',
        cover: require('@/assets/images/goods/d59c6275-bece-48b9-8f04-7ef52e9c7ec9_base_resized.jpg'),
        url: 'https://hanazonoserena.booth.pm/items/1320270'
    },
    {
        name: '💕花園セレナ＆くったんと一緒💕【アクリルキーホルダー】',
        cover: require('@/assets/images/goods/04ecc14bacb19b7532e9cbfd53c0cd56.png'),
        url: 'https://hanazonoserena.booth.pm/items/1600734'
    }
]