<template>
<div>
    <div id="goods-box">
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <a class="good-cell" v-for="(good, i) in $options.goods" :key="i" target="_blank" :href="good.url">
            <div class="card hover-highlight">
                <img :src="good.cover">
                <p>{{good.name}}</p>
            </div>
        </a>
    </div>
    <div id="modal" v-if="$route.params.goodId">
        <router-view>
        </router-view>
    </div>
</div>
</template>

<script>
import NavBar from '@/components/NavBar'
import GOODS from '@/assets/data/goods'
import Colcade from '@/helper/Colcade'

export default {
    name: 'goods-page',
    title: 'Goods',
    components: {
        // NavBar
    },
    goods: GOODS,
    mounted() {
        this.colcade = new Colcade('#goods-box', {
            columns: '.grid-col',
            items: '.good-cell'
        })
    }
}
</script>

<style lang="scss" scoped>

// use grid
@import '@/scss/grid';
@include grid(4, goods-box);

#goods-box .good-cell {
    div {
        overflow: hidden;
        margin-bottom: 6vw;
        @media screen and (min-width: $tabletWidth) {
            margin-bottom: 3vw;
        }

        img {
            display: block;
            width: 100%;
        }
        p {
            font-size: 0.7em;
            margin: 2vw;

            @media screen and (min-width: $tabletWidth) {
                margin: 1.2vw;
            }
        }
    }
}
</style>